import PageHeader from '../../components/PageHeader/PageHeader';
import headerImage from '../../assets/images/image-about-header.jpg';
import '../common/styles.scss';
import aboutImage from '../../assets/images/organisationAbout.jpg';
import ceoImage from '../../assets/images/CEO.jpg';
import dummyPImage from '../../assets/images/dummyProfileImage.jpeg';

const teamIndividualDetails = [
    {
        id: 1,
        profileImage: ceoImage,
        name: 'Dr. Rabindra Nath Ojha',
        post: 'CEO'
    },
    {
        id: 2,
        profileImage: dummyPImage,
        name: 'Dr. Kailash Khadka',
        post: 'Chairman'
    },
    {
        id: 3,
        profileImage: dummyPImage,
        name: 'Ramesh Chaulagain',
        post: 'Deputy Chairman'
    },
    {
        id: 4,
        profileImage: dummyPImage,
        name: 'Dhirendra Singh',
        post: 'Project Manager'
    },
    {
        id: 5,
        profileImage: dummyPImage,
        name: 'Dr. Kailash Khadka',
        post: 'Chairman'
    },
    {
        id: 6,
        profileImage: dummyPImage,
        name: 'Ramesh Chaulagain',
        post: 'Deputy Chairman'
    },
    {
        id: 7,
        profileImage: dummyPImage,
        name: 'Dhirendra Singh',
        post: 'Project Manager'
    }
]

const AboutUs = () => {
    return (
        <section className={'section-wrapper'}>
            <PageHeader title={'About Us'} image={headerImage}/>
            {/*Our Organization Details*/}
            <div className={'row section-content'}>
                <div className="col-12 col-md-4 section-image-wrapper">
                    <div className="bg"/>
                    <img src={aboutImage} alt="SectionImage" className={'img-fluid rounded'}/>
                </div>
                <div className="col-12 col-md-8 section-description-wrapper">
                    <div className="section-description-title"><h2>Our Organization</h2></div>
                    <div className="section-description-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab at beatae ducimus eum ex excepturi
                        exercitationem explicabo illo, in iure labore magnam, modi nesciunt pariatur provident quas quia
                        ratione rerum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto beatae consectetur
                        cum, cupiditate eum exercitationem illum impedit ipsum iure labore magni non omnis praesentium
                        qui, sequi voluptate. Accusamus aspernatur aut consequatur consequuntur ex hic iusto laudantium
                        minus nobis nulla numquam possimus, quisquam rem sunt.
                    </div>
                </div>
            </div>

             {/*Our Team Details*/}
             <div className="team-details">
                 <div className="title"><h1>Our Team</h1></div>
                 <div className="row team-wrapper">
                     {teamIndividualDetails.map((detail) => {
                         const {profileImage, name, post, id} = detail;
                         return (
                             <div key={id} className="col-12 col-md-4 col-lg-3 team-wrapper__ind">
                                 <div className="image-wrapper">
                                     <img src={profileImage} alt="ProfileImage" className={'img-fluid'}/>
                                 </div>
                                 <div className="team-name">{name}</div>
                                 <div className="team-post">{post}</div>
                             </div>
                         )
                     })}
                 </div>
             </div>
        </section>
    )
}

export default AboutUs;