import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './MainLayout.scss'

const MainLayout = ({children}) => {
    return (
        <>
            <Navbar/>
            <main className={`main container`}>
                {children}
            </main>
            <Footer/>
        </>
    )
}

export default MainLayout;