import './HistorySection.scss';
import childrenEducateImage from '../../assets/images/students-nepal.jpg';
import routes from "../../configs/routes";
import {Link} from 'react-router-dom';

const HistorySection = () => {
    return (
        <div className='history-section'>
            <div className="row flex-row-reverse wrapper">
                <div className="col-12 col-md-6 col-wrapper">
                    <div className="image-wrapper">
                        <div className="bg" />
                        <img src={childrenEducateImage} className={'img-fluid'} alt='ChildrenStudying'/>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-wrapper desc">
                    <h2>Educating Women and youth in nepal</h2>
                    <Link className={'button cta-btn'} to={routes.history}>Our History</Link>
                </div>
            </div>
        </div>
    )
}

export default HistorySection;