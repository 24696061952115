import './VideoSection.scss'

const VideoSection = () => {
    return (
        <div className={`row video-section`}>
            <div className={`col-12 col-md-7`}>
                <div>
                    <iframe width="100%" height='315px' src="https://www.youtube.com/embed/ihi55JzTCqU" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen title={'my-iframe'}/>
                </div>
            </div>
            <div className="col-12 col-md-5 pl-2 pt-3 pt-md-0 video-text">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut beatae delectus, ducimus eos et
                    exercitationem expedita illum minima perspiciatis quas quisquam, recusandae sint vero voluptatum.
                    Nesciunt quis reiciendis repudiandae?</p>
                <p className="annotate">-Lorem Ipsum</p>
            </div>
        </div>
    )
}

export default VideoSection;