import './Footer.scss'
import logo from '../../assets/dummyLogo.svg'
import {Link} from 'react-router-dom'
import routes from "../../configs/routes";
import {FaFacebook, FaTwitter, FaInstagram, FaSnapchat} from "react-icons/fa";

const Footer = () => {
    return (
        <footer>
            <div className={'container'}>
                <div className="row">
                    <div className="col-12 col-md-6 logo-description">
                        <div className={`logo-section`}>
                            <Link to='/'><
                                img className='img-fluid footer-logo' src={logo} alt={'YourLogo'}/>
                            </Link>
                        </div>
                        <div className="description">Lorem ipsum dolor sit amet.</div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row social-links-wrapper">
                            <div className="col-6 internal-links">
                                <ul>
                                    <li><Link to={routes.home}>Home</Link></li>
                                    <li><Link to={routes.history}>History</Link></li>
                                    <li><Link to={routes.about}>About</Link></li>
                                    <li><Link to={routes.contact}>Contact</Link></li>
                                </ul>
                            </div>
                            <div className="col-6 social-links">
                                <ul>
                                    <li><Link to="#fb"><FaFacebook/></Link></li>
                                    <li><Link to="#tw"><FaTwitter/></Link></li>
                                    <li><Link to="#ins"><FaInstagram/></Link></li>
                                    <li><Link to="#snap"><FaSnapchat/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cp-section text-center text-black-50 small">
                    Copyright &copy; 2021. All rights reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer;