import './Carousel.scss'
import {Carousel} from "react-responsive-carousel";
import placeHolderImage from '../../assets/images/placeholderImage.png'
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


const getCarouselChildren = (index, option) => (
    <div key={index}>
        <img src={placeHolderImage} alt={'CarouselImage'}/>
    </div>
)

const CarouselSlider = () => (
    <>
        <h2 className={'title'}>Some Glimpses Of Our Organisation</h2>
        <Carousel infiniteLoop showStatus={false} centerMode autoPlay={true} stopOnHover={true} showThumbs={false} transitionTime={800}
                  showArrows={true} interval={3000} swipeable={true} useKeyboardArrows={true}>
            {[1, 2, 3, 4, 5].map(getCarouselChildren)}
        </Carousel>
    </>
)

export default CarouselSlider;