import PageHeader from "../../components/PageHeader/PageHeader";
import historyImage from '../../assets/images/history.jpg'
import aboutImage from "../../assets/images/organisationAbout.jpg";

import '../common/styles.scss';

const OurHistory = () => {
    return (
        <section className={'section-wrapper'}>
            <PageHeader title={'Our History'} image={historyImage}/>

            {/*Single History Detail*/}
            <div className={'row section-content'}>
                <div className="col-12 col-md-4 section-image-wrapper">
                    <div className="bg"/>
                    <img src={aboutImage} alt="SectionImage" className={'img-fluid rounded'}/>
                </div>
                <div className="col-12 col-md-8 section-description-wrapper">
                    <div className="section-description-title"><h2>Lorem ipsum dolor.</h2></div>
                    <div className="section-description-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab at beatae ducimus eum ex excepturi
                        exercitationem explicabo illo, in iure labore magnam, modi nesciunt pariatur provident quas quia
                        ratione rerum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto beatae consectetur
                        cum, cupiditate eum exercitationem illum impedit ipsum iure labore magni non omnis praesentium
                        qui, sequi voluptate. Accusamus aspernatur aut consequatur consequuntur ex hic iusto laudantium
                        minus nobis nulla numquam possimus, quisquam rem sunt.
                    </div>
                </div>
            </div>

            {/*Single History Detail this alt className should be there for alternate layout*/}
            <div className={'row section-content alt'}>
                <div className="col-12 col-md-4 section-image-wrapper">
                    <div className="bg"/>
                    <img src={aboutImage} alt="SectionImage" className={'img-fluid rounded'}/>
                </div>
                <div className="col-12 col-md-8 section-description-wrapper">
                    <div className="section-description-title"><h2>Lorem ipsum.</h2></div>
                    <div className="section-description-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab at beatae ducimus eum ex excepturi
                        exercitationem explicabo illo, in iure labore magnam, modi nesciunt pariatur provident quas quia
                        ratione rerum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto beatae consectetur
                        cum, cupiditate eum exercitationem illum impedit ipsum iure labore magni non omnis praesentium
                        qui, sequi voluptate. Accusamus aspernatur aut consequatur consequuntur ex hic iusto laudantium
                        minus nobis nulla numquam possimus, quisquam rem sunt.
                    </div>
                </div>
            </div>

            {/*Single History Detail*/}
            <div className={'row section-content'}>
                <div className="col-12 col-md-4 section-image-wrapper">
                    <div className="bg"/>
                    <img src={aboutImage} alt="SectionImage" className={'img-fluid rounded'}/>
                </div>
                <div className="col-12 col-md-8 section-description-wrapper">
                    <div className="section-description-title"><h2>Lorem ipsum dolor sit.</h2></div>
                    <div className="section-description-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab at beatae ducimus eum ex excepturi
                        exercitationem explicabo illo, in iure labore magnam, modi nesciunt pariatur provident quas quia
                        ratione rerum.
                        <br/>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto beatae consectetur
                        cum, cupiditate eum exercitationem illum impedit ipsum iure labore magni non omnis praesentium
                        qui, sequi voluptate. Accusamus aspernatur aut consequatur consequuntur ex hic iusto laudantium
                        minus nobis nulla numquam possimus, quisquam rem sunt.
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurHistory;