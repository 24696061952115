import './Header.scss';
import heroImage from '../../assets/images/image-hero-friends.jpg'
import heroBg2 from '../../assets/images/hero-bg.svg'

const Header = () => {
    return (
        <div className='row header'>
            <div className="col-12 col-md-6 hero-banner-content">
                <div className={`hero-text`}>
                    <h1>Guru G Multiservices</h1>
                    <h4>
                    GuruGmultiservices is a customer service-oriented company. This company provides contractual services on the behalf of the drivers to the corporate sectors or individuals without involving any third-party people. Our drivers are licensed, highly skilled, and honest professionals. Safety is our main concern
                    </h4>
                </div>
                <a className='cta-btn' href='#'>Learn More</a>
            </div>

            <div className="col-12 col-md-6 hero-image-wrapper">
                <img src={heroImage} alt="HeroImage" className={`img-fluid w-100`}/>
                <div className="hero-bg"/>
                <img src={heroBg2} alt="HeroBg" className={`bg-2`}/>
            </div>
        </div>
    )
}

export default Header;