import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
import routes from './configs/routes';
import Home from "./pages/Home/Home";
import NoMatch from "./pages/NoMatch/NoMatch";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import OurHistory from './pages/OurHistory/OurHistory'
import ScrollToTop from "./components/ScrollToTop";


const PageWithLayout = ({Component}) => (
    <MainLayout>
        <Component/>
    </MainLayout>
)

function App() {
    return (
        <>
            <Router>
                <ScrollToTop/>
                <Switch>
                    <Route path={routes.home} exact render={() => <PageWithLayout Component={Home}/>}/>
                    <Route path={routes.about} exact render={() => <PageWithLayout Component={AboutUs}/>}/>
                    <Route path={routes.contact} exact render={() => <PageWithLayout Component={ContactUs}/>}/>
                    <Route path={routes.history} render={() => <PageWithLayout Component={OurHistory}/>}/>
                    <Route render={() => <PageWithLayout Component={NoMatch}/>}/>
                </Switch>
            </Router>
        </>
    );
}

export default App;
