import './Home.scss';
import Header from "../../components/Header/Header";
import VideoSection from "../../components/VideoSection/VideoSection";
import HistorySection from "../../components/HistorySection/HistorySection";
import CarouselSlider from "../../components/Carousel/Carousel";

const Home = (props) => {
    return (
        <div className={`homepage-wrapper`}>
            <Header />
            <VideoSection />
            <HistorySection />
            <CarouselSlider />
        </div>
    )
}

export default Home;
