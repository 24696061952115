import React, {useState} from 'react';
import {Link} from "react-router-dom";
import './Navbar.scss';
import logo from '../../assets/dummyLogo.svg';
import routes from '../../configs/routes'
import {useHistory} from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import {IoMdClose} from 'react-icons/io'

const Navbar = (props) => {
    const {location: {pathname: path}} = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <React.Fragment>
            <div className='container-fluid m-0 p-0 dark my-navbar'>
                <nav className={`container my-navbar__wrapper`}>
                    <div className="logo-wrapper">
                        <Link to={`/`}>
                            <img src={logo} alt={'yourLogo'} className={`logo`}/>
                        </Link>
                    </div>
                    <ul className="navlinks mb-0">
                        <li className={`navlinks__navlink ${path === routes.home ? 'active' : ''}`}><Link
                            to={routes.home}>Home</Link></li>
                        <li className={`navlinks__navlink ${path === routes.about ? 'active' : ''}`}><Link
                            to={routes.about}>About</Link></li>
                        <li className={`navlinks__navlink ${path === routes.contact ? 'active' : ''}`}><Link
                            to={routes.contact}>Contact</Link></li>
                        <li className={`navlinks__navlink ${path === routes.history ? 'active' : ''}`}><Link
                            to={routes.history}>Our History</Link></li>
                        <li className={`navlinks__navlink menu-icon ${isOpen? 'menu-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                            {!isOpen ?
                                <FaBars/> :
                                <IoMdClose/>}
                        </li>
                    </ul>
                    <ul className={`mobile-menu ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(false)}>
                        <li className={`navlink ${path === routes.home ? 'active' : ''}`}><Link
                            to={routes.home}>Home</Link></li>
                        <li className={`navlink ${path === routes.about ? 'active' : ''}`}><Link
                            to={routes.about}>About</Link></li>
                        <li className={`navlink ${path === routes.contact ? 'active' : ''}`}><Link
                            to={routes.contact}>Contact</Link></li>
                        <li className={`navlink ${path === routes.history ? 'active' : ''}`}><Link
                            to={routes.history}>Our History</Link></li>
                    </ul>
                </nav>
            </div>
        </React.Fragment>
    )
}

export default Navbar;