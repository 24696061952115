import PageHeader from "../../components/PageHeader/PageHeader";
import '../common/styles.scss'
import contactUs from '../../assets/images/contactus.jpg';
import contactUsImage from '../../assets/images/contactUsImage.jpg';
import {IoLocationSharp, IoCallSharp, IoMailSharp} from 'react-icons/io5';

const ContactUs = () => {
    return (
        <div className={'section-wrapper contact-us'}>
            <PageHeader title={'Contact Us'} image={contactUs}/>
            <div className={'row flex-md-row-reverse contact-us-wrapper'}>
                <div className="col-12 col-md-5 contact-detail-wrapper">
                    <img src={contactUsImage} alt={'ContactUsImage'} className={'img-fluid'}/>
                    <div className={'contact-detail-section'}>
                        <p><IoLocationSharp/> 1418 Pickens Way, Texas, 75501</p>
                        <p><IoCallSharp/> 903-277-0116 <br/> <IoCallSharp/> 903-277-0116 </p>
                        <p><IoMailSharp/> t0hgm9xzbi@temporary-mail.net</p>
                    </div>
                </div>
                <div className="col-12 col-md-7 contact-form-wrapper">
                    <div className="head">
                        <h3> Let's Talk!</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores blanditiis dolorem
                            ducimus exercitationem illo impedit,</p>
                    </div>
                    <div className="contactus-form">
                        <form action="">
                            <div className="input">
                                <label htmlFor="name">Your Name:</label><br/>
                                <input id={'name'} type={'text'} required/>
                            </div>
                            <div className="input">
                                <label htmlFor="email">Your Email:</label><br/>
                                <input id={'email'} type={'email'} required/>
                            </div>
                            <div className="input">
                                <label htmlFor="message">Your Message:</label><br/>
                                <textarea id='message' rows={4} required/>
                            </div>
                            <div className="input">
                                <input type="submit" value={'Send Message'}/>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContactUs;