import './PageHeader.scss'

const PageHeader = ({title, image}) => {
    return (
        <div className={'page-header'}>
            <div className="header-wrapper">
                <div className="header-img" >
                    <img src={image} alt="headerImage" className={'img-fluid'}/>
                </div>
                <div className="header-title">{title}</div>
            </div>
        </div>
    )
}

export default PageHeader;